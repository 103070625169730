import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import MetamaskBtn from "../components/metamask";
import { useRef } from "react";
import Footer from "../components/footer";
import { setNetworkTokens } from "../helpers/helper";
import { useParams, Navigate } from "../../node_modules/react-router-dom/dist/index";
import Loading from "../components/loading";
import { AuthLoading } from "../auth/loading";
import AuthBridge from "../auth/authBridge";
import Navbar from "../components/navbar";

const MainDirectory = () => {




  const dispatch = useDispatch()
  const messageTitle = useSelector(state => state.mainapp.notificationSlice.notifications.messageTitle)
  const message = useSelector(state => state.mainapp.notificationSlice.notifications.message)
  const alertStyle = useSelector(state => state.mainapp.notificationSlice.notifications.alertStyle)
  const autoClose = useSelector(state => state.mainapp.notificationSlice.notifications.autoClose)
  const visible = useSelector(state => state.mainapp.notificationSlice.notifications.visible)
  const id = useSelector(state => state.mainapp.notificationSlice.id)
  const timeoutRef = useRef(null);

  const currentChain = useSelector(state => state.mainapp.chainSlice.currentChain)



  useEffect(() => {
    if (visible && message) {
      if(document.querySelector('.notif-container') != null){
        document.getElementById('notif-container').style.display = 'flex';
      }

      // Set to close the notification after 5 seconds
      timeoutRef.current = setTimeout(() => {
        if(document.querySelector('.notif-container') != null){
          document.getElementById('notif-container').style.display = 'none';
        }
      }, 7000);
    } else {
      if(document.querySelector('.notif-container') != null){
        document.getElementById('notif-container').style.display = 'none';
      }
    }

    // Clear the timeout when component unmounts or dependencies change
    return () => clearTimeout(timeoutRef.current);
  }, [visible, message, id]);

  useEffect(() => {

    if(document.querySelector('.notif-container') != null){
      document.getElementById('notif-container').style.display = 'flex'
    }
    if (message == undefined) {
      if(document.querySelector('.notif-container') != null){
        document.getElementById('notif-container').style.display = 'none'
      }

    }
    else {
      if (autoClose !== 0) {
        const timeoutID = setTimeout(() => {
          if(document.querySelector('.notif-container') != null){
          document.getElementById('notif-container').style.display = 'none'
          }
        }, autoClose)
      } else {

      }
    }

  }, [message])

  useEffect(() => {
    if (visible === true) {
      if(document.querySelector('.notif-container') != null){
      document.getElementById('notif-container').style.display = 'flex'
      }
    } else {
      if(document.querySelector('.notif-container') != null){
      document.getElementById('notif-container').style.display = 'none'
      }
    }
  }, [visible])


  useEffect(() => {

    if(document.querySelector('.notif-container') != null){
    document.getElementById('notif-container').style.display = 'none';
    }
    if (message != undefined) {
      setTimeout(() => {
        if(document.querySelector('.notif-container') != null){
        document.getElementById('notif-container').style.display = 'flex'
        }
      }, 10)
    }


  }, [id])



  useEffect(() => {
    if (alertStyle === 'warning') {
      document.documentElement.style.setProperty('--notif-accent', 'var(--alt-color2)')
    } else if (alertStyle === 'error') {
      document.documentElement.style.setProperty('--notif-accent', 'var(--alt-color4)')
    } else if (alertStyle === 'success') {
      document.documentElement.style.setProperty('--notif-accent', 'var(--alt-color)')
    } else if (alertStyle === 'info') {
      document.documentElement.style.setProperty('--notif-accent', 'var(--main-color)')
    } else if (alertStyle === 'help') {
      document.documentElement.style.setProperty('--notif-accent', 'white')
    }


  }, [alertStyle])
  const closeNotification = () => {
    clearTimeout(timeoutRef.current); // Clear the timeout if manually closing
    if(document.querySelector('.notif-container') != null){
      document.getElementById('notif-container').style.display = 'none';

    }
  };

  const { chain } = useParams();
  const chainList = useSelector(state => state.mainapp.chainSlice.chainList);
  const isValidChain = chainList.some(selectedChain => selectedChain.slug === chain);

  if (!isValidChain) {
    // Redirect to a not found page or display an error message
    return <Navigate to="/polygon" replace />;
  }



  return (
    <>
      {currentChain.rpcUrl !== undefined ? 
      
      <>
        <Outlet />
      
      </>

      :
      null
      
    
    
    }
     
      <div className="notif-wrapper">
        <div id="notif-container" className={"notif-container vivify blink fadeInBottom duration-400 " + alertStyle}>
          <div className="notif-icon">
            <i className="material-icons-outlined">{
              alertStyle === 'warning' ? 'warning' :
                alertStyle === 'error' ? 'error' :
                  alertStyle === 'success' ? 'check_circle' :
                    alertStyle === 'info' ? 'info' :
                      alertStyle === 'help' ? 'help' :
                        'error'

            }</i>
          </div>
          <div className="notif-text">

            <div className="notif-body">
              <h3>{messageTitle}</h3>
              <p>{message}</p>
            </div>
            <div className="notif-title">

              <button onClick={
                () => {
                  setTimeout(() => {
                    document.getElementById('notif-container').style.display = 'none'
                  }, 10)
                }
              }>
                <i className="material-icons" >close</i>
              </button>
            </div>

          </div>
        </div>

      </div>
    </>
  );
}

export default MainDirectory;